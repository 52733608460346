import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  simpleErrorHandler(error: any, formGroup: FormGroup) {
    if (error.formError) {
      Object.keys(error.formError).forEach((key: any) => {
        const fc = formGroup.controls[key];
        if (fc) {
          fc.setErrors({
            invalid: true,
            message: error.formError[key],
          });
          fc.markAllAsTouched();
        }
      });
    }
  }
}
