import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaginatorService {
  pageIndex$ = new BehaviorSubject<number>(1);

  pageSize$ = new BehaviorSubject<number>(50);

  lengthList$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  isSearching$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
}
