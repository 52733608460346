import { TuiDay, TuiTime } from '@taiga-ui/cdk';
import { DateTime } from 'luxon';

export const transformLuxonDateToTuiDate = (date: any, { withTime = true }) => {
  const { year, month, day, hour, minute, second } = date.toObject();
  const tuiDate = new TuiDay(year, month - 1, day);
  const tuiTime = new TuiTime(hour, minute, second);

  const isValidDate = TuiDay.isValidDay(year, month - 1, day);
  const isValidTime = TuiTime.isValidTime(hour, minute, second);
  if (!isValidDate) {
    if (withTime) {
      return [null, null];
    } else {
      return null;
    }
  } else {
    if (withTime) {
      if (!isValidTime) {
        return [null, null];
      } else {
        return [tuiDate, tuiTime];
      }
    } else {
      return tuiDate;
    }
  }
};

export const transformTuiToLuxon = (date: any, { time = null }) => {
  const { year, month, day } = date;
  let withTime = false;
  let [hours, minutes, seconds]: [number, number, number] = [0, 0, 0];

  if (time) {
    ({ hours, minutes, seconds } = time);
    withTime = true;
  }
  const timezone = sessionStorage.getItem('timezone') || 'utc';
  return DateTime.fromObject(
    {
      year,
      month: month + 1,
      day,
      hour: hours,
      minute: minutes,
      second: seconds,
    },
    { zone: timezone },
  );
};
