export interface TabInfo {
  title: string;
  routerLink: string;
  iconSrc: string;
}

export const tabs: TabInfo[] = [
  {
    title: 'Accounts',
    routerLink: '/main/accounts',
    iconSrc: 'tuiIconKey',
  },
  {
    title: 'Objects',
    routerLink: '/main/objects',
    iconSrc: 'common/assets/icons/objects.svg',
  },
  {
    title: 'Users',
    routerLink: '/main/users',
    iconSrc: 'common/assets/icons/users.svg',
  },
  {
    title: 'Retranslators',
    routerLink: '/main/retranslators',
    iconSrc: 'tuiIconExternalLink',
  },
  {
    title: 'Trash',
    routerLink: '/main/trash',
    iconSrc: 'common/assets/icons/trash.svg',
  },
];
