import { Component, Inject, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { TuiContextWithImplicit, tuiPure, TuiStringHandler } from '@taiga-ui/cdk';
import { TuiAlertService, TuiDialogContext, TuiDialogService } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT, PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { timezoneUTCAvailable } from '@common/utils/timezone';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { UsersService } from '@services/users.service';
import { ErrorService } from '@common/services/error.service';
import { Store } from '@ngrx/store';
import { monitoringTabs } from '../../utils/consts/monitoring-tabs-config';
import { concatMap, tap } from 'rxjs';
import { getCurrentUser } from '@store/actions/current_user.actions';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.less'],
})
export class SettingsComponent implements OnInit {
  settingsTabs = ['General'];

  availableAddressFormat: any = [
    { name: 'country', control: new FormControl(false) },
    { name: 'state', control: new FormControl(true) },
    { name: 'city', control: new FormControl(true) },
    { name: 'road', control: new FormControl(true) },
    { name: 'house_number', control: new FormControl(true) },
  ];

  order = new Map();

  currentTab: string = this.settingsTabs[0];

  get user() {
    return this.context.data?.user;
  }

  isVisibleAdditionalParams: boolean = this.context.data?.isVisibleAdditionalParams;

  availableLanguages = [
    { id: 'en', title: 'English' },
    { id: 'ru', title: 'Русский' },
  ];

  availableTimezones: any = timezoneUTCAvailable();

  formGroup: FormGroup = new FormGroup({
    username: new FormControl(this.user?.username || '', [Validators.required]),
    fullname: new FormControl(this.user?.name || '', [Validators.required]),
    email: new FormControl(this.user?.email || '', [Validators.required, Validators.email]),
    language: new FormControl(this.user?.language || this.availableLanguages[0].id),
    timezone: new FormControl<number>(this.user?.timezone || 0),
    addressFormat: new FormControl(this.user?.addressFormat || ''),
    visibleTabsNames: new FormControl(this.user?.visibleTabsNames || ['monitoring']),
  });

  availableVisibleTabsNames: any = [];

  @tuiPure
  stringify(
    items: { id: string | number; title: string }[],
  ): TuiStringHandler<TuiContextWithImplicit<string>> {
    const map = new Map(items.map(({ id, title }) => [id, title] as [string | number, string]));

    return ({ $implicit }: TuiContextWithImplicit<string>) => map.get($implicit) || '';
  }

  constructor(
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    @Inject(POLYMORPHEUS_CONTEXT) private readonly context: TuiDialogContext<any, any>,
    @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
    private translate: TranslateService,
    private userService: UsersService,
    private errorHandler: ErrorService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    if (this.isVisibleAdditionalParams) {
      this.settingsTabs.push('Additional');

      const addressFormat = this.user?.addressFormat || [];

      this.availableVisibleTabsNames = monitoringTabs
        .filter((tab: any) => {
          if (['monitoring', 'objects', 'users'].includes(tab.name)) {
            return true;
          } else if (tab.name === 'tracks') {
            return (this.user?.commonAccesses || []).includes('access_tracks');
          } else if (tab.name === 'reports') {
            return (this.user?.commonAccesses || []).includes('access_reports');
          } else if (tab.name === 'messages') {
            return (this.user?.commonAccesses || []).includes('access_messages');
          } else if (tab.name === 'notifications') {
            return (this.user?.commonAccesses || []).includes('access_notifications');
          } else if (tab.name === 'geofences') {
            return (this.user?.commonAccesses || []).includes('access_geozones');
          } else if (tab.name === 'drivers') {
            return (this.user?.commonAccesses || []).includes('access_drivers');
          } else if (tab.name === 'buildings') {
            return (this.user?.commonAccesses || []).includes('access_buildings');
          } else if (tab.name === 'devices') {
            return (this.user?.commonAccesses || []).includes('access_devices');
          }
        })
        .map((m: any) => {
          return {
            ...m,
            control: new FormControl({
              value: this.formGroup.value.visibleTabsNames.includes(m.name),
              disabled: m?.id === 1,
            }),
          };
        });

      this.availableAddressFormat
        .sort((a: any, b: any) => {
          return addressFormat.indexOf(a.name) - addressFormat.indexOf(b.name);
        })
        .forEach((item: any) => {
          item.control.setValue(addressFormat.includes(item.name));
        });
    }
  }

  onSave() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    const addressFormat = this.availableAddressFormat;
    const orderKeys = Array.from(this.order.keys());

    const body = {
      ...this.formGroup.value,
    };

    if (this.isVisibleAdditionalParams) {
      body.visibleTabsNames = this.availableVisibleTabsNames
        .filter((item: any) => item.control.value)
        .map((item: any) => item.name);
    }
    if (orderKeys.length) {
      body.addressFormat = orderKeys
        .filter((index) => addressFormat[index].control.value)
        .sort((a, b) => this.order.get(a) - this.order.get(b))
        .map((index) => addressFormat[index].name);
    } else {
      body.addressFormat = addressFormat
        .filter((item: any) => item.control.value)
        .map((item: any) => item.name);
    }

    this.userService
      .updateUserProfile(body)
      .pipe(
        tap(() => {
          if (environment.systemType === 'cms') {
            this.store.dispatch(getCurrentUser());
          }
        }),
        tap(() => this.context.completeWith(true)),
        concatMap(() =>
          this.alerts.open(this.translate.instant('Information updated successfully')),
        ),
      )
      .subscribe({
        next: () => {},
        error: (error: any) => {
          this.errorHandler.simpleErrorHandler(error, this.formGroup);
        },
      });
  }

  onCancel() {
    this.context.completeWith(false);
  }

  openChangePasswordDialog() {
    this.dialogService
      .open<number>(new PolymorpheusComponent(ChangePasswordComponent, this.injector), {
        label: this.translate.instant('Change password'),
        size: 's',
        dismissible: false,
      })
      .subscribe();
  }
}
