import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'; // import * as Sentry from "@sentry/angular" // for Angular 10/11 instead
import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';

Sentry.init({
  dsn: 'https://d701f81ce1be7c1a1a768f254a62466c@sentry.axenta.cloud/3',
  environment: environment.production ? 'production' : 'development', // Установите окружение в зависимости от режима,
});

enableProdMode();
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((success) => console.log('Bootstrap success'))
  .catch((err) => console.error(err));
