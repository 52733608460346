<div class="container">
  <div class="paginator">
    <button type="button" class="icon right top-bottom-left pagination__btn" [disabled]="pageIndex === 1"
            [tuiHint]="'To the beginning' | translate"
            [tuiHintAppearance]="'custom'"
            (click)="clickFirstCounter()"
    >
      <tui-svg [src]="'common/assets/icons/arrow.svg'" class="left"></tui-svg>
    </button>
    <button type="button" class="icon right pagination__btn" [disabled]="pageIndex === 1"
            [tuiHint]="'Back' | translate"
            [tuiHintAppearance]="'custom'"
            (click)="clickPrevCounter()"
    >
      <tui-svg [src]="'common/assets/icons/chevron.svg'" class="left"></tui-svg>
    </button>
    <div class="paginator-text">{{textForPaginator}}</div>

    <button type="button" class="icon left pagination__btn"
            [tuiHint]="'Forward' | translate"
            [tuiHintAppearance]="'custom'"
            (click)="clickNextCounter()"
            [disabled]="isDisabledNext"
    >
      <tui-svg [src]="'common/assets/icons/chevron.svg'" class="right"></tui-svg>
    </button>
    <button type="button" class="icon left top-bottom-right pagination__btn"
            [tuiHint]="'To the end' | translate"
            [tuiHintAppearance]="'custom'"
            (click)="clickLastCounter()"
            [disabled]="isDisabledNext"
    >
      <tui-svg [src]="'common/assets/icons/arrow.svg'" class="right"></tui-svg>
    </button>

  </div>

  <div
    class="page-size"
  >
  <tui-select
    tuiTextfieldSize="s"
    [formControl]="pageSizeControl"
    [tuiHint]="'Number of elements per page' | translate"
    [tuiHintAppearance]="'custom'"
    tuiHintDirection="left"
  >

    <tui-data-list-wrapper *tuiDataList [items]="pageSize">

    </tui-data-list-wrapper>
  </tui-select>
  </div>
</div>

