<div class="container">
  <app-night-theme *ngIf="night | async"></app-night-theme>

  <section class="content">
    <app-sidebar (changeSidebarState)="changeSidebarState($event)"></app-sidebar>

    <div class="main-content" [class]="expandState ? 'with-expand' : 'without-expand'">
      <router-outlet></router-outlet>
    </div>
  </section>

  <app-push-blocked-account></app-push-blocked-account>
</div>
