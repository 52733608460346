import { AbstractControl, ValidatorFn } from '@angular/forms';

export const matchFieldsValidator = (fieldToMatch: string): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const fieldValue = control.parent?.get(fieldToMatch)?.value;
    const controlValue = control.value;

    if (fieldValue !== controlValue) {
      return { mismatch: true };
    } else {
      return null;
    }
  };
};
