import { NgModule } from '@angular/core';
import { SearchComponent } from './main/components/search/search.component';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TUI_ENGLISH_LANGUAGE, TUI_RUSSIAN_LANGUAGE } from '@taiga-ui/i18n';
import { TablePaginatorComponent } from '@common/components/table-paginator/table-paginator.component';
import { TaigaModule } from './taiga-ui.module';
import { CustomDatePipe } from '@common/utils/pipes/custom-date.pipe';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './common/assets/i18n/', '.json');
}

export function getLang() {
  const lang = localStorage.getItem('lang');
  if (lang === 'ru' || lang === 'russian') {
    return TUI_RUSSIAN_LANGUAGE;
  } else {
    return TUI_ENGLISH_LANGUAGE;
  }
}

@NgModule({
  imports: [TaigaModule, TranslateModule],
  declarations: [SearchComponent, TablePaginatorComponent, CustomDatePipe],
  exports: [SearchComponent, TablePaginatorComponent, TaigaModule, TranslateModule, CustomDatePipe],
})
export class CmsCommonComponentsModule {}
