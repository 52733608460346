import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify';
import { TUI_SANITIZER, TuiModeModule, TuiThemeNightModule } from '@taiga-ui/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, ErrorHandler, isDevMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TUI_LANGUAGE } from '@taiga-ui/i18n';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ApiUrlInterceptor } from './api-url.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  CmsCommonComponentsModule,
  createTranslateLoader,
  getLang,
} from './cms-common-components.module';
import { of } from 'rxjs';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { currentUserReducer } from '@store/reducers/current_user.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CurrentUserEffects } from '@store/effects/current_user.effects';
import { DeviceModelsEffects } from '@store/effects/device_models.effects';
import { deviceModelsReducer } from '@store/reducers/device_models.reducer';
import { SidePageModule } from '@common/components/sidepage/sidepage.module';
import { objectsReducer } from '@store/reducers/objects.reducer';
import { ObjectsEffects } from '@store/effects/objects.effects';
import { NGX_ECHARTS_CONFIG } from 'ngx-echarts';
import { ErrorInterceptor } from '@common/interceptors/error.interceptor';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { LoaderInterceptor } from '@common/interceptors/loader.interceptor';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CmsCommonComponentsModule,
    HttpClientModule,
    SidePageModule,
    TuiThemeNightModule,
    TuiModeModule,
    TranslateModule.forRoot({ defaultLanguage: 'en' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    StoreModule.forRoot({
      objects: objectsReducer,
      currentUser: currentUserReducer,
      deviceModels: deviceModelsReducer,
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    EffectsModule.forRoot([ObjectsEffects, CurrentUserEffects, DeviceModelsEffects]),
  ],
  providers: [
    { provide: TUI_SANITIZER, useClass: NgDompurifySanitizer },
    { provide: HTTP_INTERCEPTORS, useClass: ApiUrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: TUI_LANGUAGE, useValue: of(getLang()) },
    {
      provide: NGX_ECHARTS_CONFIG,
      useFactory: () => ({ echarts: () => import('echarts') }),
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
