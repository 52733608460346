<div class="sidebar" [class.expanded]="isExpanded">
  <div class="sidebar-logo cursor-pointer" (dblclick)="onDblClickLogo()">
    <tui-svg class="icon-logo" *ngIf="!isExpanded" [src]="'common/assets/logo/logo5.svg'"></tui-svg>

    <tui-svg
      class="icon-text"
      *ngIf="isExpanded"
      [src]="(night | async) ? 'common/assets/logo/logo2black.svg' : 'common/assets/logo/logo2.svg'"
    ></tui-svg>
  </div>

  <ng-container *ngFor="let tab of TABS">
    <div
      class="sidebar-content"
      [routerLink]="tab.routerLink"
      [routerLinkActive]="['is-active']"
      tuiHintAppearance="custom"
      [tuiHint]="isExpanded ? '' : (tab.title | translate)"
    >
      <tui-svg [src]="tab.iconSrc" class="icon"></tui-svg>
      <div class="text-container">
        <span class="text">{{ tab.title | translate }}</span>
      </div>
    </div>
  </ng-container>

  <div class="sidebar-settings">
    <tui-hosted-dropdown [content]="content" tuiDropdownAlign="right" [sided]="true">
      <div class="sidebar-content">
        <tui-svg [src]="'common/assets/icons/settings.svg'" class="transition"></tui-svg>

        <div class="text-container" *ngIf="isExpanded">
          <span class="text">{{ 'Settings' | translate }}</span>
        </div>
      </div>
    </tui-hosted-dropdown>

    <ng-template #content>
      <app-settings-tooltip [isDarkTheme]="true"></app-settings-tooltip>
    </ng-template>
  </div>

  <div class="sidebar-switcher">
    <div
      class="sidebar-content"
      (click)="setIsExpanded(!isExpanded)"
      [tuiHint]="(isExpanded ? '' : 'Expand') | translate"
      [tuiHintAppearance]="'custom'"
    >
      <tui-svg
        [src]="'common/assets/icons/arrow.svg'"
        class="transition"
        [class]="isExpanded ? 'left' : 'right'"
      ></tui-svg>

      <div class="text-container" *ngIf="isExpanded">
        <span class="text">{{ 'Collapse' | translate }}</span>
      </div>
    </div>
  </div>
</div>
