import { Pipe, PipeTransform } from '@angular/core';
import { formatDefaultDateFromISO } from '@common/utils/format-date';

@Pipe({
  name: 'customDate',
})
export class CustomDatePipe implements PipeTransform {
  transform(
    value: string | null | undefined,
    date?: string | null | undefined,
    time?: string | null | undefined,
  ): string {
    return formatDefaultDateFromISO(value, date, time);
  }
}
