import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { getCurrentUser } from '@store/actions/current_user.actions';
import { getDeviceModels } from '@store/actions/device_models.actions';
import { TuiThemeNightService } from '@taiga-ui/addon-doc';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.less'],
})
export class MainComponent implements OnInit {
  expandState = false;

  constructor(
    @Inject(TuiThemeNightService) readonly night: TuiThemeNightService,
    private store: Store,
  ) {
    this.night.next(!!window.localStorage.getItem('theme') || false);
  }

  ngOnInit() {
    const sessionStorageToken = sessionStorage.getItem('cmsToken');

    if (!sessionStorageToken) {
      sessionStorage.removeItem('cmsToken');
      window.location.href = '/';
    }

    this.store.dispatch(getCurrentUser());
    this.store.dispatch(getDeviceModels());
  }

  changeSidebarState($event: any) {
    this.expandState = $event;
  }
}
