import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { AbstractTuiThemeSwitcher } from '@taiga-ui/cdk';

@Component({
  selector: 'app-night-theme',
  templateUrl: './night-theme.component.html',
  styleUrls: ['./night-theme.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NightThemeComponent extends AbstractTuiThemeSwitcher {}
