import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TuiAlertService } from '@taiga-ui/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ObjectsService {
  changeObjectFromSocket$: any = new BehaviorSubject({ id: null, action: null });

  constructor(
    private httpClient: HttpClient,
    @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
    private translate: TranslateService,
  ) {}
  create(form: any) {
    return this.httpClient.post('cms/objects/', form);
  }

  update(form: any, objectId: any) {
    return this.httpClient.put(`cms/objects/${objectId}/`, form);
  }

  updateIsActiveObject(objectId: number, state: boolean) {
    return this.httpClient.post(`cms/objects/${objectId}/activate/`, { state });
  }

  objectsList(params: any = null) {
    let httpParams = new HttpParams();

    if (params?.page && params?.perPage) {
      httpParams = httpParams
        .set('page', params?.page.toString())
        .set('per_page', params?.perPage.toString());
    }

    if (params?.search) {
      httpParams = httpParams.set('search', params?.search);
    }

    if (params?.sortedValue) {
      httpParams = httpParams.set('ordering', params?.sortedValue);
    }

    if (params?.accountId) {
      httpParams = httpParams.set('account_id', params.accountId.toString());
    }
    return this.httpClient.get('cms/objects/', { params: httpParams });
  }

  shortList(params: any = null) {
    let httpParams = new HttpParams();

    if (params?.accountId) {
      httpParams = httpParams.set('account_id', params.accountId.toString());
    }

    return this.httpClient.get('cms/objects/short/', { params: httpParams });
  }

  linkingObjectsByObjectId(objectId: number) {
    let httpParams = new HttpParams();

    httpParams = httpParams.set('object_id', objectId.toString());

    return this.httpClient.get(`cms/object_user_access/`, { params: httpParams });
  }

  logs(
    id: number,
    date: any,
    isFilter: boolean,
    filtersObj?: any,
    page?: number,
    perPage?: number,
  ) {
    let params = new HttpParams();

    params = params.set('entity_id', id.toString());
    params = params.set('entity', 'object');

    params = params.set('t_after', date.startDate).set('t_before', date.endDate);

    if (page && perPage) {
      params = params.set('page', page.toString()).set('per_page', perPage.toString());
    }

    return this.httpClient.get(`cms/journal/`, { params });
  }

  getObjectById(objectId: number, full: boolean = true) {
    let params = new HttpParams().set('full', full);
    return this.httpClient.get(`cms/objects/${objectId}/`, { params });
  }

  getAvailableParamsById(objectId: number) {
    return this.httpClient.get(`objects/available_params/${objectId}/`);
  }

  getAvailableParamsByIds(objectIds: number[]) {
    return this.httpClient.post('objects/available_params/', { objectIds });
  }

  deleteObject(objectId: number) {
    return this.httpClient.delete(`cms/objects/${objectId}/`);
  }

  massDeleteObject(ids: number[]) {
    return this.httpClient.post('cms/objects/mass_delete/', { objectsIds: ids });
  }

  changeAccount(body: { objectsIds: number[]; accountId: number }) {
    return this.httpClient.post('cms/objects/change_account/', body);
  }

  changeCurrentValueForCalculatedSensors(objectId: number, sensorId: number, newCurrentValue: any) {
    return this.httpClient.post(`cms/objects/${objectId}/sensors/${sensorId}/set_current_value/`, {
      newCurrentValue,
    });
  }

  sendCommand(objectId: number, commandId: number) {
    return this.httpClient.post(`objects/send_command/`, { objectId, commandId });
  }

  alert(name: string, action: string) {
    return this.alerts.open(
      this.translate.instant(action, {
        name,
        context: this.translate.instant('Object'),
      }),
    );
  }
}
