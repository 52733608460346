<ng-container *tuiLet="currentUser$ | async as user">
  <div class="content-container">
    <span class="name"
      ><b>{{ user?.name || ('First name' | translate) }}</b></span
    >
    <span class="username">{{ user?.username || ('User name' | translate) }}</span>
  </div>
  <tui-data-list role="menu">
    <tui-opt-group>
      <div class="night" *ngIf="this.isDarkTheme">
        <span [class.isActive]="!nightThemeControl.value">{{ 'Light' | translate }}</span>
        <tui-toggle [formControl]="nightThemeControl"></tui-toggle>
        <span [class.isActive]="nightThemeControl.value">{{ 'Dark' | translate }}</span>
      </div>

      <button tuiOption (click)="openAccountSettings(user)">
        <tui-svg class="not-hover" [src]="'common/assets/icons/settings.svg'"></tui-svg>
        <span class="tui-space_left-2">{{ 'My settings' | translate }}</span>
      </button>

      <a href="https://docs.axenta.tech/" target="_blank">
        <button tuiOption class="doc">
          <tui-svg class="not-hover" [src]="'tuiIconExternalLink'"></tui-svg>
          <span class="tui-space_left-2">{{ 'Документация' | translate }}</span>
        </button>
      </a>

      <button tuiOption (click)="exit()">
        <tui-svg class="not-hover" [src]="'common/assets/icons/logoutLogin.svg'"></tui-svg>
        <span class="tui-space_left-2">{{ 'Logout' | translate }}</span>
      </button>
    </tui-opt-group>
  </tui-data-list>
</ng-container>
