import { Component } from '@angular/core';
import { selectCurrentUser } from '@store/selectors/current_user.selectors';
import { skip, take } from 'rxjs';
import { DateTime } from 'luxon';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-push-blocked-account',
  templateUrl: './push-blocked-account.component.html',
  styleUrls: ['./push-blocked-account.component.less'],
})
export class PushBlockedAccountComponent {
  open = false;
  blockingAccountDate: any = null;

  constructor(private store: Store) {}

  ngOnInit() {
    const sessionStorageBlockingAccount = sessionStorage.getItem('accountBlockingDatetime');

    this.store
      .select(selectCurrentUser)
      .pipe(skip(1), take(1))
      .subscribe((val: any) => {
        this.blockingAccountDate = val?.accountBlockingDatetime;
        if (!sessionStorageBlockingAccount && val?.accountBlockingDatetime) {
          const dateTimeString = val.accountBlockingDatetime;
          const timezone = sessionStorage.getItem('timezone') || 'utc';
          const dateTime = DateTime.fromISO(dateTimeString, { zone: timezone });
          const currentDate = DateTime.local();
          const differenceInDays = Math.abs(currentDate.diff(dateTime, 'days').days);
          this.open = differenceInDays <= 5;
        }
      });
  }

  toggle($event: any) {
    this.open = $event;
    sessionStorage.setItem('accountBlockingDatetime', 'accountBlockingDatetime');
  }
}
