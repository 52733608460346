<div class="form" [formGroup]="formGroup">
  <div>
    <span>{{ 'Old password' | translate }} *</span>
    <tui-input-password
      formControlName="oldPassword"
      tuiTextfieldSize="s"
      [pseudoFocus]="
        !formGroup.controls['oldPassword'].valid && formGroup.controls['oldPassword'].touched
      "
      [tuiHintAppearance]="'custom'"
      tuiHintContent
    >
      {{ 'Enter old password' | translate }}
      <input tuiTextfield type="email" translate="no" autocomplete="new-password" />
    </tui-input-password>
    <tui-error [error]="formGroup.get('oldPassword')?.errors?.['message']"></tui-error>
  </div>
  <div>
    <span>{{ 'New password' | translate }} *</span>
    <tui-input-password
      formControlName="newPassword"
      tuiTextfieldSize="s"
      [tuiHintAppearance]="'custom'"
      tuiHintContent
      [pseudoFocus]="
        !formGroup.controls['newPassword'].valid && formGroup.controls['newPassword'].touched
      "
    >
      {{ 'Enter new password' | translate }}

      <input tuiTextfield type="email" translate="no" autocomplete="new-password" />
    </tui-input-password>
    <tui-error [error]="formGroup.get('newPassword')?.errors?.['message']"></tui-error>

    <tui-error
      *ngIf="formGroup.get('newPassword')?.errors?.['minlength']"
      [error]="'Minimum length 6 characters' | translate"
    >
    </tui-error>
  </div>
  <div>
    <span>{{ 'Confirm new password' | translate }} *</span>
    <tui-input-password
      formControlName="confirmPassword"
      tuiTextfieldSize="s"
      [pseudoFocus]="
        !formGroup.controls['confirmPassword'].valid &&
        formGroup.controls['confirmPassword'].touched
      "
      [tuiHintAppearance]="'custom'"
      tuiHintContent
    >
      {{ 'Enter your new password again' | translate }}
      <input tuiTextfield type="email" translate="no" autocomplete="new-password" />
    </tui-input-password>
    <tui-error [error]="formGroup.get('confirmPassword')?.errors?.['message']"></tui-error>

    <tui-error
      *ngIf="
        formGroup.get('confirmPassword')?.errors?.['mismatch'] &&
        formGroup.controls['confirmPassword'].touched
      "
      [error]="'Password mismatch' | translate"
    ></tui-error>
  </div>
</div>
<div class="btn-block">
  <button tuiButton type="button" appearance="outline" size="s" (click)="onCancel()">
    {{ 'Cancel' | translate }}
  </button>
  <button tuiButton (click)="onSave()" size="s">
    {{ 'SAVE' | translate }}
  </button>
</div>
