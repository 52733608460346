import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, filter } from 'rxjs';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.less'],
})
export class SearchComponent {
  @Output() searchEvent = new EventEmitter<string | null>();
  @Input() isLoading = false;

  public searchControl = new FormControl('');

  constructor() {
    this.searchControl.valueChanges
      .pipe(
        filter((value) => !value),
        debounceTime(2000),
      )
      .subscribe((value) => {
        this.searchEvent.emit(value);
      });
  }

  onClickEnter(value: string | null) {
    if (this.isLoading) return;

    this.searchEvent.emit(value);
  }
}
