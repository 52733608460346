<div class="search-block">
  <tui-input
    [formControl]="searchControl"
    tuiTextfieldSize="s"
    [tuiTextfieldCleaner]="true"
    (keyup.enter)="onClickEnter(searchControl.value)"
  >
    {{ 'Search' | translate }}...
    <input tuiTextfield type="text" [readonly]="isLoading" />
  </tui-input>
  <button
    tuiButton
    size="s"
    type="button"
    appearance="transparent"
    class="icon"
    (click)="onClickEnter(searchControl.value)"
  >
    <tui-svg [src]="'tuiIconSearch'"></tui-svg>
  </button>
</div>
