import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, tap } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { UsersService } from '@services/users.service';
import { getCurrentUser, storeCurrentUser } from '@store/actions/current_user.actions';
import { TranslateService } from '@ngx-translate/core';
import { TuiLanguageSwitcher } from '@taiga-ui/i18n';

@Injectable()
export class CurrentUserEffects {
  getCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCurrentUser),
      exhaustMap(() =>
        this.usersService.getCurrentUser().pipe(
          tap((user: any) => {
            this.translate.use(user.language);
            this.switcher.setLanguage(user.language);
            localStorage.setItem('lang', user.language);
            sessionStorage.setItem(
              'timezone',
              `UTC${user.timezone >= 0 ? '+' : ''}${user.timezone}`,
            );
          }),
          map((currentUser: any) => {
            return storeCurrentUser({ currentUser });
          }),
          catchError(() => EMPTY),
        ),
      ),
    ),
  );

  constructor(
    @Inject(TuiLanguageSwitcher) private readonly switcher: TuiLanguageSwitcher,
    public translate: TranslateService,
    private actions$: Actions,
    private usersService: UsersService,
  ) {}
}
