import { createReducer, on } from '@ngrx/store';
import {
  addObject,
  storeObjects,
  updateLastMessageForObject,
  updateObjectById,
} from '@store/actions/objects.actions';

export const initialState: any = {
  list: [],
  loaded: false,
};

export const objectsReducer = createReducer(
  initialState,
  on(storeObjects, (state, { list }) => ({ ...state, list, loaded: true })),
  on(updateLastMessageForObject, (state, data) => {
    return {
      ...state,
      list: state.list.map((object: any) => {
        const obj: any = Object.values(data || {}).find((value: any) => value.oid === object.id);
        return {
          ...object,
          lastMessage: obj
            ? {
                ...object.lastMessage,
                ...{
                  ...obj.m,
                  pos: obj.m?.pos ? obj.m.pos : object.lastMessage?.pos,
                },
              }
            : object.lastMessage,
        };
      }),
    };
  }),
  on(updateObjectById, (state, data) => {
    const { updatedObject } = data;
    return {
      ...state,
      list: state.list.map((object: any) => {
        return object.id === updatedObject.id ? { ...object, ...updatedObject } : object;
      }),
    };
  }),
  on(addObject, (state, data) => {
    const { createdObject } = data;
    return {
      ...state,
      list: [...state.list, createdObject],
    };
  }),
);
