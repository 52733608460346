import { createReducer, on } from '@ngrx/store';
import { storeCurrentUser } from '@store/actions/current_user.actions';

export const initialState = {
  currentUser: null,
};

export const currentUserReducer = createReducer(
  initialState,
  on(storeCurrentUser, (state, { currentUser }) => ({ ...state, currentUser })),
);
